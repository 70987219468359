<template>
    <div class="login-body">
        <div class="login-wrapper">
            <div class="login-panel">
                <img src="/assets/layout/images/logo-dark.svg" class="logo" alt="diamond-layout"/>
                <form @submit.prevent="handleSubmit">
                    <div class="login-form">
                        <h2>Новый пароль</h2>
                        <p>Укажите новый пароль для аккаунта</p>
                        <input type="hidden" :value="form.fields.token" name="token" id="token">
                        <div class="field">
                            <span class="p-input-icon-left">
                                <i class="pi pi-envelope"/>
                                <InputText id="email" v-model="form.fields.email"
                                           disabled
                                           type="email"
                                           placeholder="Е-майл"
                                           :area-invalid="form.errors.email || form.errors.token ? true : null"
                                           :aria-describedby="form.errors.email || form.errors.token ? form.errors.email : null"
                                           :class="{ 'p-invalid' : form.errors.email || form.errors.token }"/>
                            </span>
                            <div v-if="form.errors.email || form.errors.token" class="p-error text-left"><small>{{ form.errors.email || form.errors.token
                                }}</small></div>
                        </div>
                        <div class="field">
                            <span class="p-input-icon-left">
                                <i class="pi pi-lock"/>
                                <Password id="password" v-model="form.fields.password"
                                          placeholder="Пароль"
                                          toggleMask
                                          :area-invalid="form.errors.password ? true : null"
                                          :aria-describedby="form.errors.password ? form.errors.password : null"
                                          :class="{ 'p-invalid' : form.errors.password }"/>
                            </span>
                            <div v-if="form.errors.password" class="p-error text-left"><small>{{ form.errors.password
                                }}</small>
                            </div>
                        </div>
                        <div class="field">
                            <span class="p-input-icon-left">
                                <i class="pi pi-lock"/>
                                <Password id="password_confirmation" v-model="form.fields.password_confirmation"
                                          placeholder="Пароль повторно"
                                          toggleMask
                                          :area-invalid="form.errors.password ? true : null"
                                          :aria-describedby="form.errors.password ? form.errors.password: null"
                                          :class="{ 'p-invalid' : form.errors.password }"/>
                            </span>
                            <div v-if="form.errors.password" class="p-error text-left"><small>{{ form.errors.password
                                }}</small>
                            </div>
                        </div>
                        <Button label="СМЕНИТЬ" type="submit" :loading="registerLoading"></Button>
                    </div>
                </form>
                <p>Уже есть аккаунт?
                    <router-link :to="{name : 'login'}">Вход</router-link>
                </p>
            </div>
            <div class="login-image">
                <div class="login-image-content">
                    <h1>Access to your</h1>
                    <h1>Diamond</h1>
                    <h1>Account</h1>
                    <h3>
                        Lorem ipsum dolor sit amet, consectetur <br/>
                        adipiscing elit. Donec posuere velit nec enim <br/>
                        sodales, nec placerat erat tincidunt.
                    </h3>
                </div>
                <div class="image-footer">
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>
                    <div class="icons">
                        <i class="pi pi-github"></i>
                        <i class="pi pi-twitter"></i>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { reactive, computed } from 'vue'
import ToastMessageService from '../services/ToastMessageService'
import AuthService from '../services/AuthService'
import FormService from '../services/FormService'
import router from '../router'
import { useStore } from 'vuex'

export default {
    props: {
        email: {
            type: String,
            required: true
        },
        token: {
            type: String,
            required: true
        }
    },
    setup (props) {
        const store = useStore()
        const formService = new FormService()
        const registerLoading = computed(() => store.state.auth.resetPasswordLoading)
        const authService = new AuthService()
        const toastMessageService = new ToastMessageService()
        const defaultForm = () => {
            return {
                fields: {
                    email: props.email,
                    token: props.token,
                    password: null,
                    password_confirmation: null
                },
                errors: {}
            }
        }
        const form = reactive(defaultForm())

        const resetForm = () => {
            Object.assign(form, defaultForm())
        }

        const handleSubmit = async () => {
            await authService.resetPassword(form.fields).then(async () => {
                resetForm()
                await router.push({ name: 'login' })
                await toastMessageService.add({
                    detail: 'Пароль аккаунта был обновлен'
                })
            }).catch(exception => formService.fillErrors(exception, form))
        }

        return {
            form, resetForm, handleSubmit, registerLoading
        }
    }
}
</script>
